/*
font-family: "Lacquer", system-ui;
font-family: "Mountains of Christmas", serif;
font-family: "Playpen Sans", cursive;
font-family: "Inter", sans-serif;
*/

@font-face{
  font-family: 'Miama';
  src: url('./assets/fonts/miama.regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

:root {
  --color-light: #afc45f;
  --color-medium: #6c8220;
  --color-dark: #3b4216;
  --color-accent-one: #DCE7B2;
  --color-accent-two: #ecd5e5;
}

/* width */
::-webkit-scrollbar{
  width: 1em;
}

/* Track */
::-webkit-scrollbar-track{
  background: white;
}

/* Handle */
::-webkit-scrollbar-thumb{
  background: var(--color-accent-one);
  border-radius: 20px;
  border: 3px solid var(--color-accent-one);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover{
  background: #b9d562;
  border: 3px solid #b9d562;
}

html{
  scroll-behavior: smooth;
}

body {
  margin: 0;
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
  background-color: #FDFDF8;
}

.container{
  position: absolute;
  z-index: 1;
  min-width: 100vw;
  min-height: 100vh;
}

.backgroundImg{
  position: absolute;
  z-index: 0;
  height: 100%;
  opacity: 0.4;
}

.subpageDaisies{
  width: 50%;
}

.subpageDaisiesBottom{
  width: 45%;
  height: auto;
}

.topLeft{
  top: 0;
  left: 0;
}

.topRight{
  top: 0;
  right: 0;
}

.bottomLeft{
  bottom: 0;
  left: 0;
}

.bottomRight{
  bottom: 0;
  right: 0;
}

.backLink{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: fit-content;
  text-decoration: none;
  color: black;
  transform: scale(1.0);
  transition: 0.1s ease-in;
  font-size: 18px;
}

.backLink:hover{
  transform: scale(1.1);
  transition: 0.1s ease-out;
}

.subpageContainer{
  font-family: "Lacquer", system-ui;
  text-align: left;
  width: 70vw;
}

.subpageText{
  margin-left: 40px;
  margin-top: 20px;
  margin-bottom: 15px;
  width: 80%;
}

.subpageHeader{
  display: flex;
  flex-direction: row;
  align-items: last baseline;
}

.subpageHeaderText{
  display: flex;
  flex-direction: column;
}

.subpageHeader h1{
  font-size: 50px;
  margin: 0px;
}

.subpageHeader h2{
  font-size: 30px;
  margin: 0px;
  text-align: left;
}

#aboutContact{
  font-size: 22px;
  font-family: "Lacquer", system-ui;
  margin-top: 25px;
}