.exploreContainer{
    background-size: 40px 40px;
    background-image:
        linear-gradient(to right, rgba(58, 153, 221, 0.4) 1px, transparent 1px),
        linear-gradient(to bottom, rgba(58, 153, 221, 0.4) 1px, transparent 1px);

    padding-top: 10px;
    width: 100%;
    font-family: "Inter", sans-serif;
    font-weight: 400;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    .backLink{
        font-family: 'Lacquer';
    }
}

.exploreHeader{
    margin-left: 0px;
    width: 80%;
    display: flex;
    flex-direction: row;
    align-items: last baseline;
}

#dinosaur{
    width: 100px;
}

.exploreContentBox{
    width: 80%;
    border: solid 1.5px black;
    background-color: rgba(236, 213, 229, 0.5);
    margin: 20px 0px;
    padding: 10px;
    text-align: left;

    h3{
        margin: 2px 0px 10px 0px;
    }
}

.exploreLink{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 25px;

    p{
        margin: 0px;
        line-height: 1.5;
    }

    a{
        color: black;
    }

    img{
        margin-right: 5px;
        height: 40px;
    }
}

#maplocationdot{
    font-size: 40px;
    margin-right: 5px;
}