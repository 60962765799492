#teddybear{
    height: 100px;
    transform: rotate(-10deg);
    margin-left: 10px;
}

#aboutContact p{
    margin: 0px 0px 10px 0px;
}

.aboutText{
    width: 80%;
}

.aboutLine{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}

.aboutBio{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    text-align: left;

    font-family: "Playpen Sans", cursive;
    font-size: 20px;
    background-color: rgba(236, 213, 229, 0.5);

    img{
        height: 200px;
        margin: 10px;
    }

    .aboutBioText{
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        text-align: left;
    }
}

.textBasic{
    font-family: "Inter", sans-serif;
    font-size: 30px;
    margin: 0px;
}

.textCursive{
    font-family: 'Miama';
    font-size: 50px;
    margin: 0px;
    line-height: 1.05;
}

.textWhimsy{
    font-family: "Playpen Sans", cursive;
    font-size: 20px;
    margin: 0px;
}

.textHandwritten{
    font-family: "Lacquer", system-ui;
    font-size: 30px;
    margin: 0px;
}

@media (max-width: 768px){
    .subpageHeader h1{
        font-size: 40px;
    }
    
    .subpageHeader h2{
        font-size: 20px;
    }

    .aboutText {
        width: 85%;
        
    }

    .aboutBio{
        flex-direction: column;
        font-size: 16px;

        p{
            margin: 5px;
        }

        img{
            height: 175px;
        }
    }

    .textBasic{
        font-size: 20px;
    }
    
    .textCursive{
        font-size: 40px;
    }

    .textHandwritten{
        font-size: 20px;
    }

}