.homeContainer{
    overflow: hidden;
    height: 100vh;
    width: 100vw;
}

.headerText{
    text-align: left;
    margin-top: 40px;
    margin-left: 40px;
}

#title{
    font-family: "Lacquer", system-ui;
    font-size: 50px;
    margin: 0px;
}

#tagline{
    font-family: "Lacquer", system-ui;
    font-size: 30px;
    margin: 0px;
}

.navContainer{
   margin-top: 30px;
   margin-right: 60px;
   transform: rotate(-10deg);
}

.navRow{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    margin-top: 5px;
    margin-bottom: 5px;
}

.navButton{
    text-decoration: none;
    color: black;
    transform: scale(1.0) rotate(10deg);
    transition: 0.2s ease-in;
}

.navButton:hover{
    transform: scale(1.1) rotate(10deg);
    transition: 0.2s ease-out;
}

.navImage{
    height: 100px;
    padding: 0px;
    margin: 0px;
}

#fishImage{
    height: 80px;
}

#ladybugImage{
    transform: rotate(30deg);
}

#lunamothImage{
    transform: rotate(-10deg);
}

.navText{
    font-family: "Mountains of Christmas", serif;
    font-size: 40px;
    padding: 0px;
    margin: 0px;
}

#contactText{
    font-family: "Lacquer", system-ui;
    z-index: 1;
    width: 100%;
    position: absolute;
    bottom: 40px;
    font-size: 30px;
}

#aboutButton{
    margin-right: 70px;
    margin-bottom: 25px;
}

#projectsButton{
    margin-left: 10px;
}

#youtubeButton{
    margin-top: 30px;
}

#instagramButton{
    margin-right: 15px;
}

#homeDaisiesBottom{
    width: 50%;
    height: auto;
    opacity: 0.6;
}

#whiteRabbit{
    position: absolute;
    z-index: 0;
    width: 25%;
    height: auto;
}


@media (max-width: 1024px){
    .navContainer{
        margin-top: 40px;
    }
    
    .navText{
        font-size: 35px;
    }

    .navImage{
        height: 90px;
        padding: 0px;
        margin: 0px;
    }
    
    #fishImage{
        height: 70px;
    }

    #contactText{
        bottom: 20px;
        font-size: 25px;
    }
}

@media (max-width: 768px){
    .navContainer{
        margin-right: 0px;
    }
    
    .navImage{
        height: 80px;
        padding: 0px;
        margin: 0px;
    }
    
    #fishImage{
        height: 60px;
    }

    .navText{
        font-size: 30px;
    }

    #contactText{
        bottom: 10px;
        left: 10%;
        font-size: 20px;
        text-wrap: wrap;
        width: 50%;
        text-align: left;
    }

    #homeDaisies{
        height: 100vh;
        width: 100vw;
    }

    #homeDaisiesBottom{
        display:none;
    }

    #whiteRabbit{
        width: 30%;
    }
}