.projectsContainer{
    padding-top: 10px;
    width: 100%;
    background-color: black;
    color: var(--color-accent-two);
    font-family: "Inter", sans-serif;
    font-weight: 400;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.projectsNav{
    margin-bottom: 40px;
    width: 80%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;

    list-style-type: none;
    padding: 0px;
    border: solid 2px var(--color-accent-two);
}

.projectsNavButton{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 5px 10px;
    padding: 0px;

    text-decoration: underline;
    color: var(--color-accent-two);
    font-size: 16px;
    background: none;
    border: none;
    cursor: pointer;
    outline: none;
}

.projectNavIcon{
    font-size: 20px;
    margin: 0px;
}

.projectsPreTitle{
    font-weight: 400;
    font-size: 20px;
    margin: 0px;
}

.projectsTitle{
    font-family: 'Miama';
    font-weight: 400;
    font-size: 100px;
    margin: 0px;
}

.projectsBow{
    width: 100px;
}

.unboxedText{
    width: 80%;
    margin: 20px 0px;
    text-align: justify;
}

.projectsContentBox{
    width: 80%;
    border: solid 2px var(--color-accent-two);
    margin: 20px 0px;
    padding: 10px;
    text-align: left;

    h3{
        font-family: 'Lacquer', sans-serif;
        font-weight: 400;
        font-size: 35px;
        margin: 5px;
    }
}

.projectsList{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
}

.projectTile{
    margin: 10px 10px 15px 10px;

    p{
        margin: 5px 0px 15px 0px;
    }

    a{
        color: var(--color-accent-two);
        border: solid 1px var(--color-accent-two);
        border-radius: 12px;
        padding: 5px;
        text-decoration: none;
    }

    a:hover{
        background-color: var(--color-accent-two);
        color: black;
    }
}

.websiteTile{
    width: 30%;

    img{
        width: 100%;
    }
}

.musicVideoTile{
    width: 47%;
    

    iframe{
        width: 100%;
        height: 360px;
    }
}

.instagramEmbed{
    display: 'flex';
    justify-content: 'center';
    margin: 10px;
}

.projectsFillerImage{
    width: 300px;
    height: 200px;
    background-color: var(--color-accent-two);
    margin: 5px;
}

@media (max-width: 1024px){
    .projectTile{
        margin-left: 5px;
        margin-right: 5px;
    }

    .websiteTile{
        width: 45%;
    }

    .musicVideoTile{
        width: 95%;
    }
}

@media (max-width: 768px){
    .projectsList{
        justify-content: center;
    }

    .websiteTile{
        width: 95%;
        margin-bottom: 20px;
    }

    .musicVideoTile{
        iframe{
            height: 220px;
        }
    }
}